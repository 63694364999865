<template>
  <div class="flex flex-col items-center">
    <project-card v-for="(project , index) in projects" :key="project.id" :project="project" :index="index"/>
  </div>
</template>

<script>
import ProjectCard from "./card"
export default {
  name: "project-List",
  data() {
    return {
      projects: [
        {id:1 , icon:"/assets/images/Logo.png" , shortDesc:"Instant Delivery", description:"London's First Peer to Peer delivery platform founded in 2018.", cover:"/assets/images/Group 3508.png" , location:"London - United kingdom" , background:"#28112E" , color:"#F1F1F1" , priority:"text" , appleStore:"https://apps.apple.com/gb/app/peyk-the-instant-couriers/id1431759089" , playGoogle:"https://play.google.com/store/apps/developer?id=PEYK+LTD&hl=en_GB&gl=US" , website:"https://peyk.uk"},
        {id:2 , icon:"/assets/images/Artboard 1.png" , shortDesc:"Instant Delivery", description:"London's fastest peer to peer delivery platform expanded to Qatar through an exclusive partnership with Pass.", cover:"/assets/images/Mask Group 19.png" , location:"Doha - Qatar" , background:"#f2ddc8" , color:"#28112E" , priority:"image" , appleStore: "https://apps.apple.com/gb/app/pass-delivery/id1540486238", playGoogle: "https://play.google.com/store/apps/details?id=qa.pass&hl=en&gl=US", website: "https://pass.qa"},
        {id:3 , icon:"/assets/images/icon2_transparent.png" , shortDesc:"On demand grocery", description:"Need any groceries? Fling it. Its Canada's first on demand grocery delivery platform.", cover:"/assets/images/Group 3510.png" , location:"Vancouver - Canada" , background:"#205F97" , color:"#F1F1F1" , priority:"text" , appleStore: "" , playGoogle: "", website: ""},
        {id:4 , icon:"/assets/images/Group 3517.png" , shortDesc:"Marketplace", description:"Buying and selling commodities and construction equipment marketplace for the Middle East.", cover:"/assets/images/Laptop.png" , location:"UAE" , background:"#293462" , color:"#F1F1F1" , priority:"image" , website: "http://clear-asset.com"},
        {id:5 , icon:"/assets/images/Group 157.png" , shortDesc:"Fine dinning", description:"Exclusive food pre-ordering facility for Persian cuisine in the heart of Toronto.", cover:"/assets/images/Group 3512.png" , location:"Toronto, Canada" , background:"#090909" , color:"#F1F1F1" , priority:"text" , website: "http://www.darband.ca"},
      ]
    }
  },
  components:{
    ProjectCard,
  }
}
</script>

<style scoped>

</style>