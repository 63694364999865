import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/tailwind/tailwind.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone , faAngleDown , faAngleRight , faPhoneVolume , faEnvelope  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
library.add(faPhone , faAngleDown , faAngleRight , faPhoneVolume , faEnvelope );
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount('#app');
