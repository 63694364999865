<template>
  <header class="bg-pink-1000 font-koulen text-white text-center relative z-1 flex flex-col justify-center items-center lg:bg-header lg:bg-cover	lg:bg-right lg:bg-no-repeat">
    <nav class="font-openSans font-bold text-gray-150 flex w-full justify-center items-center pt-8">
      <img class="lg:absolute lg:left-60 w-24" src="/assets/images/vitta.png" alt="">
      <ul class="hidden lg:flex">
        <li class="mx-7"><a href="#projectsSection">Projects</a></li>
        <li class="mx-7"><a href="#expertiseSection">Expertise</a></li>
        <li class="mx-7"><a href="#contactSection">Contact</a></li>
      </ul>
      <a class="hidden lg:flex lg:absolute lg:right-36" href="#contactSection">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
        </svg>
      </a>
    </nav>
    <div class="w-full flex flex-col flex-1 justify-center items-center">
      <h1 class="font-light text-4.5xl flex flex-col lg:text-8xl">
        <span class="mb-3.5">WE <span class="text-pink-550">DESIGN</span>, <span class="text-pink">CREATE</span></span>
        <span>AND <span class="text-pink-550">MANAGE</span></span>
      </h1>
      <p class="flex flex-col lg:flex-row lg:space-x-1.5 font-Roboto font-normal mb-20 mt-7">
        <span> WEB AND APP SOLUTIONS FOR </span>
        <span> ALL YOUR NEEDS </span>
      </p>
      <a href="#contactSection" class="bg-pink-550 relative text-lg py-2.5 px-14 rounded-full  w-52 after:w-full after:border after:absolute after:h-full after:rounded-full after:left-1 after:-bottom-1.5 after:-z-1">TALK TO US <font-awesome-icon icon="angle-right" class="ml-2"/></a>
      <a href="#projectsSection" class="absolute font-Roboto bottom-4 left-2/4 transform -translate-x-2/4">
        <span class="flex flex-col text-xs">Slide Down <font-awesome-icon icon="angle-down" class="animate-bounce"/></span>
      </a>
    </div>
  </header>
</template>
<script>
export default {
  name: "header-compoent",
  // created() {
  //   console.log(window.innerHeight);
  //   document.querySelector("header").style.height=window.innerHeight;
  // },
  mounted() {
    const el =document.querySelector("header");
    console.log(window.innerHeight);
    el.style.height=window.innerHeight+"px";
  }

}
</script>

<style scoped>

</style>