<template>
  <footer>
    <div class="text-white bg-pink-1000 font-medium flex flex-col md:flex-row md:items-end md:w-full md:justify-between md:py-16 px-5 xl:px-36">
      <div class="flex flex-col items-center md:items-start py-16 md:py-0">
        <img class="w-40 xl:w-60" src="/assets/images/vitta.png" alt="">
        <p class="text-lg font-koulen mt-5 xl:text-2.5xl">''You imagine , we create''</p>
      </div>
      <div class="flex flex-col items-center md:items-end pb-4 md:pb-0">
        <ul class="flex">
          <li class="mx-1.5">
            <a href="">
              <img class="w-8 xl:w-10" src="/assets/images/2559769_media_network_social_whatsapp_icon.svg" alt="">
            </a>
          </li>
          <li class="mx-1.5">
            <a href="">
              <div class="w-8 h-8 xl:w-10 xl:h-10 bg-white rounded-full flex justify-center items-center">
                <img class="w-2/3" src="/assets/images/2639840_instagram_icon.svg" alt="">
              </div>
            </a>
          </li>
          <li class="mx-1.5">
            <a href="">
              <img class="w-8 xl:w-10" src="/assets/images/5279111_network_fb_social media_facebook_facebook logo_icon.svg" alt="">
            </a>
          </li>
        </ul>
        <p class="text-xs xl:text-lg	font-openSans font-normal mt-4">© 2022 Vita technologies. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-component"
}
</script>

<style scoped>

</style>