<template>
  <Header/>
  <div class="lg:container lg:mx-auto px-5 xl:px-44">
    <section id="projectsSection" class="bg-white">
      <h2 class="font-koulen font-normal text-pink-1000 py-10 text-2.5xl flex flex-col items-center lg:flex-row lg:justify-center lg:space-x-2.5 lg:text-5xl lg:py-12">
        <span>Our quality shows</span>
        <span>commitment</span>
      </h2>
      <ProjectList/>
    </section>
    <section id="expertiseSection">
      <ExpertiseList/>
    </section>
  </div>
  <section>
    <div class="bg-pink-1000 w-full my-10  overflow-x-scroll scrollbar-horizontal">
      <ul class="flex flex-nowrap py-8 mx-auto">
        <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex justify-center items-center border-r border-gray-400 last:border-r-0 p-4 shrink-0" v-for="(logo , index) in logos" :key="index">
            <img class="h-8 object-contain" :src="logo.icon" alt="">
        </li>
      </ul>
    </div>
  </section>
  <div class="lg:container lg:mx-auto px-5 xl:px-44">
    <section id="contactSection">
      <Contact/>
    </section>
    <section>
      <Question/>
    </section>
  </div>
  <Footer/>
</template>

<script>
  import Header from "./components/share/header"
  import ProjectList from "./components/project/List"
  import ExpertiseList from "./components/expertise/list"
  import Contact from "./components/contact/index"
  import Question from "@/components/question/index";
  import Footer from "./components/share/footer"
export default {
  name: 'App',
  data() {
    return {
      logos:[
        {icon:"/assets/images/google-cloud-3.png"},
        {icon:"/assets/images/amazon-s3.png"},
        {icon:"/assets/images/digitalocean-2.png"},
        {icon:"/assets/images/2560px-Stripe_Logo,_revised_2016.svg.png"},
        {icon:"/assets/images/2560px-Twilio-logo-red.svg.png"},
        {icon:"/assets/images/Mailchimp.png"},
      ],
      lengthLogos:2,
    }
  },
  components: {
    Header,
    ProjectList,
    ExpertiseList,
    Contact,
    Footer,
    Question
  },
  created() {
   if(screen.width>=768 && screen.width<1024){
     this.lengthLogos=3;
   }else if(screen.width>=1536){
     this.lengthLogos=4;
   }
    console.log(this.lengthLogos)
  }
}
</script>
