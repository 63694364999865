<template>
  <div class="px-4 py-10 md:my-20">
    <h2 class="font-koulen font-normal pb-10 md:pb-20 text-2.5xl lg:text-5xl">
      FAQ
    </h2>
    <div class="font-Roboto font-medium flex flex-col md:flex-row 2xl:text-lg md:grid md:grid-cols-2 md:items-center md:gap-8 xl:gap-24">
      <ul>
        <li class="py-3 px-5 bg-zinc-50 rounded-lg my-4 relative anima first:mt-0 md:last:mb-0 before:border-animation-before after:border-animation-after " v-for="(question , index) in questions" :key="index"  @click="active=index , clicked=true" :class="{'bg-pink-50 before:animate-border-before after:animate-border-after':active==index , '':active!=index}">
          <span class="cursor-pointer" :class="{'text-pink-550': active==index}">{{question.question}}</span>
        </li>
      </ul>
      <div class="border border-pink-550 rounded-lg p-5 h-full bg-zinc-50 md:border-none">
        <p class="font-bold text-pink-550 mb-4">{{ questions[active].question }}</p>
        <p class="text-pink-1000 text-xs 2xl:text-lg">
          {{questions[active].answer}}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "question-index",
  data() {
    return {
      questions: [
        {id:1 , question:"Does Vitta include after sales support?",answer:"We surely do. Vitta believes in commitment towards technical projects from the very beginning till the end."},
        {id:2 , question:"Can I pay in installments?",answer:"We are flexible to your finances in drafting contracts with you. Depending on the project fees, we can fit in a suitable installment plan."},
        {id:3 , question:"What happens if I am not happy?",answer:"We have a satisfaction guarantee procedure. We will work on your project until your satisfaction is fulfilled."},
        {id:4 , question:"Is there any geographical restrictions on the projects?",answer:"Not at all. Vitta can complete projects anywhere around the world. We have specialised mentors in different regions and can complete your project based on local needs."},
      ],
      active:0,
      clicked:false,
    }
  },
  created(){
    this.updateActive();
  },
  methods:{
    updateActive(){
      if(!this.clicked){
        if(this.active<this.questions.length){
          var timer = setTimeout(()=>{
            this.active+=1;
            this.updateActive();
          },"5000")
        }else{
          this.active=0;
          this.updateActive();
        }
      }else{
        this.active-=1
        clearTimeout(timer);
        return
      }
    }
  }
}
</script>