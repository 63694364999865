<template>
  <div class="font-Roboto font-normal pt-10 flex flex-col md:flex-row md:gap-5 md:grid md:grid-cols-12">
    <div class="md:col-span-5 lg:col-span-4">
      <h2 class="font-koulen text-black pb-10 text-2.5xl lg:text-5xl">
        Have a project in mind?
      </h2>
      <p class="text-lg">Contact us for a free consultation and to kickstart your imaginary project.</p>
      <ul>
        <li class="flex flex-row items-center my-9">
          <div class="w-10 mr-5">
            <img class="w-full" src="/assets/images/Frame 2.png" alt="">
          </div>
          <div class="flex flex-col">
            <span class="text-2xl font-bold text-gray-900">Call</span>
            <span class="text-lg text-slate-400">+447776777787</span>
          </div>
        </li>
        <li class="flex flex-row items-center my-9">
          <div class="w-10 mr-5">
            <img class="w-full" src="/assets/images/9 - Email 1.png" alt="">
          </div>
          <div class="flex flex-col">
            <span class="text-2xl font-bold text-gray-900">Email</span>
            <span class="text-lg text-slate-400">salman@vittatechnologies.com</span>
          </div>
        </li>
        <li class="flex flex-row items-center my-9">
          <div class="w-10 mr-5">
            <img class="w-full" src="/assets/images/14 - Map Location 1.png" alt="">
          </div>
          <div class="flex flex-col">
            <span class="text-2xl font-bold text-gray-900">Location</span>
            <span class="text-lg text-slate-400">NW10 London, United kingdom</span>
          </div>
        </li>
      </ul>
    </div>
    <form action="" class="bg-zinc-50	rounded-10px text-lg md:col-span-7 lg:col-span-8">
      <div class="flex flex-col px-5 py-3">
        <label for="name" class="mb-6">Name & Company</label>
        <div class="flex flex-row items-center rounded-10px bg-white">
          <img class="w-5 mx-5" src="/assets/images/Group.png" alt="full name">
          <input type="text" placeholder="Full name here" name="name" id="name" class="py-4 flex-1 placeholder:text-slate-400 rounded-10px">
        </div>
      </div>
      <div class="flex flex-col px-5 py-3">
        <label for="email" class="mb-6">Your mail</label>
        <div class="flex flex-row items-center rounded-10px bg-white">
          <img class="w-5 mx-5" src="/assets/images/9 - Email 1.png" alt="email">
          <input type="text" placeholder="Your email address" name="email" id="email" class="py-4 flex-1 placeholder:text-slate-400 rounded-10px">
        </div>
      </div>
      <div class="flex flex-col px-5 py-3">
        <label for="" class="mb-6">Project Budget</label>
        <div class="bg-white rounded-10px flex overflow-x-auto scrollbar-horizontal">
          <label for="budget1" class="cursor-pointer px-1 my-3 border-r last:border-r-0 flex justify-center">
            <input type="radio" name="budget" id="budget1" class="hidden peer">
            <span class="text-neutral-900 text-opacity-20 py-6 w-36 text-center text-lg rounded-10px peer-checked:bg-fuchsia-1000 peer-checked:text-white">£500-1k</span>
          </label>
          <label for="budget2" class="cursor-pointer px-1 my-3 border-r last:border-r-0 flex justify-center">
            <input type="radio" name="budget" id="budget2" class="hidden peer">
            <span class="text-neutral-900 text-opacity-20 py-6 w-36 text-center text-lg rounded-10px peer-checked:bg-fuchsia-1000 peer-checked:text-white">£1k-£3k</span>
          </label>
          <label for="budget3" class="cursor-pointer px-1 my-3 border-r last:border-r-0 flex justify-center">
            <input type="radio" name="budget" id="budget3" class="hidden peer">
            <span class="text-neutral-900 text-opacity-20 py-6 w-36 text-center text-lg rounded-10px peer-checked:bg-fuchsia-1000 peer-checked:text-white">£3k-5k</span>
          </label>
          <label for="budget4" class="cursor-pointer px-1 my-3 border-r last:border-r-0 flex justify-center">
            <input type="radio" name="budget" id="budget4" class="hidden peer">
            <span class="text-neutral-900 text-opacity-20 py-6 w-36 text-center text-lg rounded-10px peer-checked:bg-fuchsia-1000 peer-checked:text-white">£5k-10k</span>
          </label>
          <label for="budget5" class="cursor-pointer px-1 my-3 border-r last:border-r-0 flex justify-center">
            <input type="radio" name="budget" id="budget5" class="hidden peer">
            <span class="text-neutral-900 text-opacity-20 py-6 w-36 text-center text-lg rounded-10px peer-checked:bg-fuchsia-1000 peer-checked:text-white">£10k+</span>
          </label>
        </div>
      </div>
      <div class="flex flex-col px-5 py-3">
        <label for="message" class="mb-6">Message</label>
        <textarea placeholder="Type your message here" name="message" id="message" class="p-4 flex-1 placeholder:text-slate-400  rounded-10px"></textarea>
      </div>
      <div class="flex flex-col px-5 py-3">
        <button class="bg-pink-550 text-white rounded-10px w-48	py-5">Send message</button>
      </div>
    </form>
  </div>

</template>

<script>
export default {
  name: "contact-index",

}
</script>

<style scoped>

</style>