<template>
  <div class="px-4 py-10 md:my-20">
    <h2 class="font-koulen font-normal pb-10 md:pb-20 text-2.5xl text-center lg:text-5xl">
      VITTA provides full support on
    </h2>
    <div class="font-Roboto font-medium flex flex-col md:flex-row md:grid md:grid-cols-2 md:items-center md:gap-8 xl:gap-24">
      <ul class="text-lg lg:text-2xl md:w-fit">
        <li class="py-1.5 first:pt-0 md:py-3 relative after:h-px after:absolute after:w-0 after:h-0 after:border-2 after:border-transparent" v-for="(expertise , index) in expertises" :key="index" :class="{'after:animate-full-width':active==index , 'after:animate-none-width':active!=index}">
          <div class="w-full pb-1 flex items-center justify-between lg:pb-4">
            <span class="cursor-pointer" @click="active=index , clicked=true" :class="{'text-fuchsia-1000': active==index}">{{expertise.title}}</span>
            <div class="w-5 md:ml-4" v-if="active==index" :class="{'animate-fade-in':active==index , 'animate-fade-out':active!=index}">
              <img class="w-full" src="/assets/images/Path 12101.png" alt="">
            </div>
          </div>
        </li>
      </ul>
      <div class="mt-10 md:mt-0">
        <div class="mb-10 md:mb-6 flex justify-center">
          <img class="w-full object-contain object-center h-56 md:h-80 " :src="expertises[active].image" alt="">
        </div>
        <p class="text-pink-1000 text-xs lg:text-lg" style="max-height: 200px">
          {{expertises[active].description}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "expertize-list",
  data() {
    return {
      expertises: [
        {id:1 , title:"UI and graphical planning",description:"Vitta will proudly start any project from the very initial stage of UI design using the latest techniques and structures for any development.",image:"/assets/images/Group 3513.png"},
        {id:2 , title:"Web development",description:"Whether you need a simple Wordpress or Woo-commerce website, or a strong native VUE structure, Vitta ensures that a responsive website is designed in the fastest time and most affordable pricing.",image:"/assets/images/Group 3514.png"},
        {id:3 , title:"PWA and native app development",description:"Vitta tech has a strong VUEjs team that can code any PWA also known as web apps. These are great alternatives to having native apps in iOS and Android. ",image:"/assets/images/Layer 3.png"},
        {id:4 , title:"Project management",description:"Our team can take full control over any tech based project. This includes project drafting, planning, management, enhancement and after sales. A whole package at once.",image:"/assets/images/Group 3529.png"},
        {id:5 , title:"Server and Database",description:"We can manage your servers and database with the latest cloud solutions such as AWS and DigitalOcean. A trusted reliable team for your precious data.",image:"/assets/images/Group 3516.png"},
      ],
      active:0,
      clicked:false,
    }
  },
  created(){
    this.updateActive();
  },
  methods:{
    updateActive(){
      if(!this.clicked){
        if(this.active<this.expertises.length){
          var timer = setTimeout(()=>{
            this.active+=1;
            this.updateActive();
          },"5000")
        }else{
          this.active=0;
          this.updateActive();
        }
      }else{
        this.active-=1
        clearTimeout(timer);
        return
      }
    }
  }
}
</script>